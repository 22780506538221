import { useRef } from 'react'
import { RadioCircleFilledIcon, RadioCircleUnfilledIcon } from '../icons'
import { TypographyParagraph } from '../typography'
import clsx from 'clsx'

const RadioInput = ({
	size = 'small',
	name = 'Radio Input',
	current = false,
	isInactive = false,
	onClick,
}: {
	size: 'small' | 'large'
	name: string
	current: boolean
	isInactive?: boolean
	onClick?: () => void
}) => {
	const ref = useRef(null)
	return (
		<div
			className={clsx(
				isInactive ? 'cursor-not-allowed' : 'cursor-pointer',
				'my-2 flex items-center',
			)}
		>
			<input
				ref={ref}
				id={name}
				name={name}
				type="radio"
				defaultChecked={current}
				className={clsx('hidden')}
				onClick={onClick}
			/>
			{/* @ts-ignore */}
			<label
				htmlFor={name}
				className={clsx(
					isInactive ? 'cursor-not-allowed' : 'cursor-pointer',
					'flex gap-x-2',
					isInactive
						? 'text-textInactiveLight dark:text-textInactiveDark'
						: 'text-textPrimaryLight dark:text-textPrimaryDark',
					current ? 'text-textPrimaryLight' : 'text-grey400',
					'ml-2 block',
				)}
			>
				<div className="group">
					{current ? (
						<RadioCircleFilledIcon size={size} />
					) : (
						<RadioCircleUnfilledIcon size={size} />
					)}
				</div>
				<TypographyParagraph size={size === 'small' ? 'xSmall' : 'medium'}>
					{name}
				</TypographyParagraph>
			</label>
		</div>
	)
}

export default RadioInput
